import React from 'react';

import Layout from '@gaia/Layout';
import { Typography, Box, Stack } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';
import SEO from '../components/seo';
import PartnersList from '../components/Partners/PartnersList';
import partnersImg from '../images/partners.svg';

const PartnersTemplate = ({ pageContext: { partners } }) => (
    <Layout>
        <SEO title="Our Partners" />
        <Stack
            component="section"
            alignItems="center"
            sx={{ mb: { xs: 9, sm: 6 }, flexDirection: { sm: 'row' }, gap: { sm: 3 } }}
        >
            <Box flexBasis="55%">
                <Typography variant="h1" sx={{ color: 'primary.main', mb: { xs: 2, sm: 4 } }}>
                    Our Partners
                </Typography>
                <Typography paragraph variant="subtitle1" sx={{ mb: 4 }}>
                    Collaboration is part of the solution. With our amazing partners, we are working
                    together to help solve the problems around climate change and work towards our
                    shared vision of restoring a healthy planet.
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 4 }}>
                    If you are looking for more groups fighting climate change, explore our{' '}
                    <Link
                        underline="hover"
                        sx={{ color: 'primary.main' }}
                        to="/activism-groups"
                        aria-label="Activism Groups"
                    >
                        list of activism groups
                    </Link>{' '}
                    to find new ways you can get involved.
                </Typography>
            </Box>
            <Box flexBasis="45%" textAlign="center">
                <img src={partnersImg} alt="A group of four people working around a table." />
            </Box>
        </Stack>
        <PartnersList partners={partners} />
    </Layout>
);

export default PartnersTemplate;
