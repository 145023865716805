import React from 'react';
import { Stack, Box, Grid } from '@mui/material';
import PartnersCard from './PartnersCard';

interface Partner {
    name: string;
    logo: string;
    website: string;
    description: string;
}

interface PartnersListParams {
    partners: Partner[];
}

const PartnersList = ({ partners }: PartnersListParams) => {
    console.log(partners);
    return (
        <Stack
            component="section"
            alignItems="start"
            flexDirection="column"
            sx={{ gap: { sm: 3 } }}
        >
            <Box>
                <Grid container columnSpacing={3} rowSpacing={5}>
                    {partners.map(partner => (
                        <Grid key={partner.name} item xs={12} sm={6} md={4} lg={4}>
                            <PartnersCard {...partner} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Stack>
    );
};

export default PartnersList;
