import React from 'react';
import { Typography, Card, CardHeader, CardMedia, CardActions, CardContent } from '@mui/material';
import { Link } from 'gatsby-material-ui-components';

interface PartnerCardParams {
    name: string;
    logo: string;
    website: string;
    description: string;
}

const PartnerCard = ({ name, logo, website, description }: PartnerCardParams) => {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardMedia
                component="img"
                alt={name}
                image={logo}
                sx={{ objectFit: 'contain', height: 186, mb: 0, pb: 1, pt: 3, px: 3 }}
            />
            <CardHeader
                titleTypographyProps={{ variant: 'subtitle1' }}
                title={name}
                sx={{ paddingY: 1 }}
            ></CardHeader>
            <CardContent sx={{ pt: 0, pb: { xs: 0, sm: 1 }, flexGrow: 1 }}>
                <Typography variant="body1">{description}</Typography>
            </CardContent>
            <CardActions>
                <Link
                    to={website}
                    underline="hover"
                    variant="body1"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: 'primary.main', px: 1, py: 0 }}
                >
                    Go to website
                </Link>
            </CardActions>
        </Card>
    );
};

export default PartnerCard;
